/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SVGInjector from '../../components/svg-injector';
import svgBlendTop from '../../images/svg-blend-top.svg';
import svgBlendBottom from '../../images/svg-blend-bottom.svg';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components), {Highlight} = _components;
  if (!Highlight) _missingMdxReference("Highlight", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Playing around with blending two SVG images through a canvas element.\nOne design constraint was to have the SVG be inline in order to do\ndynamic color changing before applying the blending (and to support resizing).\nThis makes use of the ", React.createElement(_components.a, {
    href: "https://github.com/iconic/SVGInjector"
  }, "SVGInjector"), " library."), "\n", React.createElement(_components.p, null, "The code is:"), "\n", React.createElement(Highlight, {
    code: `
function importSVG(sourceSVG, targetCanvas) {
  var svgXml = (new XMLSerializer()).serializeToString(sourceSVG)
  var ctx = targetCanvas.getContext('2d')
  ctx.globalCompositeOperation = 'multiply'
  var img = new Image()
  img.height = '400'
  img.width = '400'
  img.onload = function() {
    ctx.drawImage(img, 0, 0, 400, 400)
  }
  img.src = "data:image/svg+xml;base64," + btoa(svgXml)
}

var options = {
  evalScripts:'never',
  each: function(svg) {
    svg.style.height = '400px'
    svg.style.width = '400px'
    importSVG(svg, document.querySelector('canvas'))
  }
}

SVGInjector(document.querySelectorAll('.replace'), options)
`.trim(),
    lang: "javascript"
  }), "\n", React.createElement("img", {
    className: "replace",
    src: "/images/svg-blend-top.svg",
    height: "400",
    width: "400"
  }), "\n", React.createElement("img", {
    className: "replace",
    src: "/images/svg-blend-bottom.svg",
    height: "400",
    width: "400"
  }), "\n", React.createElement("canvas", {
    height: "400",
    width: "400"
  }), "\n", React.createElement(SVGInjector));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
