import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'

function importSVG(sourceSVG, targetCanvas) {
  var svgXml = new XMLSerializer().serializeToString(sourceSVG)
  var ctx = targetCanvas.getContext('2d')
  ctx.globalCompositeOperation = 'multiply'
  var img = new Image()
  img.height = '400'
  img.width = '400'
  img.onload = function () {
    ctx.drawImage(img, 0, 0, 400, 400)
  }
  img.src = 'data:image/svg+xml;base64,' + btoa(svgXml)
}

var options = {
  evalScripts: 'never',
  each: function (svg) {
    console.log('svg is: ', svg)
    svg.style.height = '400px'
    svg.style.width = '400px'
    importSVG(svg, document.querySelector('canvas'))
  },
}

function SVGInjector() {
  useEffect(() => {
    const s = document.createElement('script')
    s.src = withPrefix('javascripts/svg-injector.js')
    // console.log('writing script node with src: ', withPrefix(src))
    document.body.appendChild(s)

    setTimeout(() => {
      // console.log('svg injector: ', window.SVGInjector)
      window.SVGInjector(document.querySelectorAll('.replace'), options)
    }, 2000)
  }, [])
  return <></>
}
export default SVGInjector
